<template>
  <div class="space-y-4">
    <asom-button icon="pen" :text="''" @click="showModal = true" />
    <asom-modal
      title="Edit Station Threshold Configuration"
      v-model="showModal"
      size="lg"
      dismissible
    >
      <asom-alert v-if="error" variant="error" :errorMessage="error" />
      <asom-grid :sm="1" :md="2">
        <asom-form-field
          class="col-span-2"
          label="Line"
        >
          <p class="mt-3 font-bold">{{get(thresholdFormData, 'line')}}</p>
        </asom-form-field>
        <asom-form-field
          class="col-span-2"
          label="Station"
        >
          <p class="mt-3 font-bold">{{get(thresholdFormData, 'station')}}</p>
        </asom-form-field>
        <asom-form-field
          label="Max PSC"
          :state="inputStates('thresholdFormData.maxPSC')"
          error="Max PSC value is required."
          required
        >
          <asom-input-text
            prepend-text="$"
            :state="inputStates('thresholdFormData.maxPSC')"
            v-model.number="thresholdFormData.maxPSC"
          />
        </asom-form-field>
        <asom-form-field
          label="Min PSC"
          :state="inputStates('thresholdFormData.minPSC')"
          error="Min PSC value is required."
          required
        >
          <asom-input-text
            prepend-text="$"
            :state="inputStates('thresholdFormData.maxPSC')"
            v-model.number="thresholdFormData.minPSC"
          />
        </asom-form-field>
        <asom-form-field
          label="Max AFC"
          :state="inputStates('thresholdFormData.maxAFC')"
          error="Max AFC value is required."
          required
        >
          <asom-input-text
            prepend-text="$"
            :state="inputStates('thresholdFormData.maxAFC')"
            v-model.number="thresholdFormData.maxAFC"
          />
        </asom-form-field>
        <asom-form-field
          label="Min AFC"
          :state="inputStates('thresholdFormData.minAFC')"
          error="Min AFC value is required."
          required
        >
          <asom-input-text
            prepend-text="$"
            :state="inputStates('thresholdFormData.minAFC')"
            v-model.number="thresholdFormData.minAFC"
          />
        </asom-form-field>
        <asom-form-field
          label="Max AFC Drawn"
          :state="inputStates('thresholdFormData.maxAFCDrawn')"
          error="Max AFC Drawn value is required."
          required
        >
          <asom-input-text
            prepend-text="$"
            :state="inputStates('thresholdFormData.maxAFCDrawn')"
            v-model.number="thresholdFormData.maxAFCDrawn"
          />
        </asom-form-field>
        <div class="flex flex-row-reverse pt-4 px-4 gap-4 col-span-2">
          <asom-button
            @click="onConfirm"
            :disabled="isSubmitting"
            :is-loading="isSubmitting"
            text="Confirm"
          />
          <asom-button
            @click="showModal = false"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </asom-grid>
    </asom-modal>
  </div>
</template>
<script>
import get from "lodash.get";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { updateStationCashThreshold } from "@/services/cashManagement.service";

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  props: {
    stationId: {
      type: String,
    },
    lineId: {
      type: String,
    },
  },
  data() {
    const thresholdConfig = this.$store.getters[
      "cashManagement/configuration/getThresholdOfStation"
    ](this.stationId);
    return {
      showModal: false,
      isSubmitting: false,
      error: null,
      thresholdFormData: {
        line: get(thresholdConfig, 'line'),
        lineId: get(thresholdConfig, 'lineId'),
        station: get(thresholdConfig, 'station'),
        stationId: get(thresholdConfig, 'stationId'),
        maxPSC:  get(thresholdConfig, 'maxPSC', 0),
        minPSC:  get(thresholdConfig, 'minPSC', 0),
        maxAFC:  get(thresholdConfig, 'maxAFC', 0),
        minAFC:  get(thresholdConfig, 'minAFC', 0),
        maxAFCDrawn:  get(thresholdConfig, 'maxAFCDraft', 0),
      }
    }
  },
  computed: {
    stationAndLine() {
      return {
        stationId: this.stationId,
        lineId: this.lineId
      }
    }
  },
  mounted() {
    this.loadStationData();
  },
  watch: {
    stationAndLine() {
      this.loadStationData();
    },
  },
  validations() {
    return {
      thresholdFormData: {
        lineId: {
          required,
        },
        stationId: {
          required,
        },
        maxPSC: {
          required,
          minValue: minValue(1)
        },
        maxAFC: {
          required,
          minValue: minValue(1),
        },
        maxAFCDrawn: {
          required,
          minValue: minValue(1),
        },
      },
    };
  },
  methods: {
    get,
    loadStationData() {
      const thresholdConfig = this.$store.getters[
        "cashManagement/configuration/getThresholdOfStation"
      ](this.stationId, this.lineId);
      this.thresholdFormData = {
        line: get(thresholdConfig, 'line'),
        lineId: get(thresholdConfig, 'lineId'),
        station: get(thresholdConfig, 'station'),
        stationId: get(thresholdConfig, 'stationId'),
        maxPSC:  get(thresholdConfig, 'maxPSC', 0),
        minPSC:  get(thresholdConfig, 'minPSC', 0),
        maxAFC:  get(thresholdConfig, 'maxAFC', 0),
        minAFC:  get(thresholdConfig, 'minAFC', 0),
        maxAFCDrawn:  get(thresholdConfig, 'maxAFCDraft', 0),
      }
    },
    onConfirm() {
      this.error = null;
      this.v$.$touch();
      if (this.v$.thresholdFormData.$invalid) {
        this.error = "Please complete all required fields";
      } else {
        this.submit();
      }
    },
    async submit() {
      this.error = null;
      this.isSubmitting = true;
      const result = await updateStationCashThreshold({
        configurations: [{
          lineId: this.lineId,
          stationId: this.stationId,
          maxPSC: this.thresholdFormData.maxPSC,
          minPSC: this.thresholdFormData.minPSC,
          maxAFC: this.thresholdFormData.maxAFC,
          minAFC: this.thresholdFormData.minAFC,
          maxAFCDrawn: this.thresholdFormData.maxAFCDrawn,
        }]
      });
      if (!result.success) {
        this.error = result.payload;
      } else {
        this.$router.go(this.$router.currentRoute);
      }
      this.isSubmitting = false;
    },
  },
};
</script>

<style></style>
